import React from 'react';
import { useEffect, useState, useRef } from 'react'
import { Button, 
  Box,
  Typography } from '@mui/material'
import { getGcodeStatus,
  getGcodeFile,
  getHasGcodeStatus,
  generateGcode,
  // checkGcodeUpdated
  } from '../models/GcodeManager'
import fileDownload from 'js-file-download'
import CircularProgress from '@mui/material/CircularProgress'
import DownloadIcon from '@mui/icons-material/Download'
import { useTranslation } from 'react-i18next'


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{props.label}</Typography>
      </Box>
    </Box>
  )
}


export default function Gcode(props) {
  
  const [projectId, ] = useState(props.projectId)
  const [gcodeStatus, setGcodeStatus] = useState(null)
  const [gcodeFailed, setGcodeFailed] = useState(false)
  const [gcodeWatching, setGcodeWatching] = useState(false)
  const [gcodeGenerated, setGcodeGenerated] = useState(false)

  const { t } = useTranslation()
  
  let gcodeTimer = useRef()
  
  function getStatusWithProgress() {
    const status = [
      "started",
      "completed"
    ]
    if (0 <= status.indexOf(gcodeStatus)) {
      return (status.indexOf(gcodeStatus) + 1) + '/' + status.length
    }
    return ""
  }

  function intervalGcodeStatus() {
    getGcodeStatus(projectId).then(data => {
      console.log('getGcodeStatus', data)
      setGcodeStatus(data)
    })
  }
  
  
  function callGenerateGcode() {
    generateGcode(projectId)
    setGcodeWatching(true)
  }
  
  
  useEffect(() => {
    getHasGcodeStatus(projectId).then(data => {
      console.log('getHasGcodeStatus', data)
      if (data === true) {
        setGcodeWatching(true)
      } else {
        // status.jsonは存在しないのでSTLの生成を行う。
        callGenerateGcode()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])
  

  useEffect(() => {
    if (gcodeWatching === true) {
      gcodeTimer.current = setInterval(intervalGcodeStatus, 3000)
    } else {
      clearInterval(gcodeTimer.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gcodeWatching])
  
  
  useEffect(() => {
    console.log('gcodeStatus ', gcodeStatus)
    if (gcodeStatus === "completed" || gcodeStatus === "error") {
      setGcodeWatching(false)
      if (gcodeStatus === "completed") {
        setGcodeGenerated(true)
      } else {
        setGcodeFailed(true)
      }
      /*
      checkGcodeUpdated(projectId).then(data => {
        console.log('checkGcodeUpdated ' + data)
        if (data === false) {
          // 処理は終わっているが、status.jsonはkbdata.jsonより古いのでSTLファイルは古い情報で生成されたことになる
          // なので、再度STLの生成を行う。
          setGcodeGenerated(false)
          callGenerateGcode()
        } else if (data === true) {
          setGcodeWatching(false)
          if (gcodeStatus === "completed") {
            setGcodeGenerated(true)
          } else {
            setGcodeFailed(true)
          }
        }
      })
      */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gcodeStatus])
  
  
  useEffect(() => {
    console.log("gcodeGenerated " + gcodeGenerated)
    if (gcodeGenerated) {
      props.setDone(true)
    }
  }, [gcodeGenerated, props])
  
  async function gcodeHandler(event) {
    if (gcodeGenerated) {
      const response = await getGcodeFile(projectId)
      fileDownload(response.Body, 'gcode.zip')
    }
  }
  
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center' }}>
      { gcodeGenerated ?
          <Button onClick={gcodeHandler} endIcon={<DownloadIcon />}>
            {t('G-Code')}
          </Button>
        :
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center' }}>
            { !gcodeFailed ?
              <CircularProgressWithLabel label={getStatusWithProgress()} />
            :
              ""
            }
            { gcodeStatus && !gcodeFailed ?
              <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>status: {gcodeStatus}</Typography>
            :
              ""
            }
            <Typography>{t('G-Code')}</Typography>
            { gcodeFailed ?
              <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>genetation failed</Typography>
            :
              ""
            }
          </Box>
      }
    </Box>
  )
}
