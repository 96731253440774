import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "any kind": "Any kind of keyboards",
      "designing": "design by yourself",
    }
  },
  ja: {
    translation: {
      "any kind": "いろんなキーボードを",
      "designing": "デザインして",
      "generating data": "データを作って",
      "ordering": "注文して",
      "buying parts": "部品を入手して",
      "soldering": "はんだづけして",
      "writing firmware": "ファームウエアを書き込んで",
      "using": "使えるようになります",
      "thank you for watching": "見てくれてありがとう",
      "Select one of the models.": "どちらかのモデルを選んでください",
      "Generating files. It might take about 90 sec.": " ファイルを作っています。90秒ほどかかるかもしれません。",
      "Board": "基板",
      "Keyswitch": "キースイッチ",
      "Keycap": "キーキャップ",
      "Enclosure": "ケース",
      "Module": "モジュール",
      "Assembly": "組み立て",
      "Shipping": "配送",
      "Open for payment and shipping address.": "ボタンを押すと配送先の指定と決済の画面が開きます",
      "Title": "項目",
      "Name": "名前",
      "Price ( JPY )": "価格（円）",
      "Pcs": "個数",
      "Subtotal ( JPY )": "小計（円）",
      "Total": "合計",
      "ABOUT": "これなに?",
      "Keycaps": "キーキャップ",
      "Keyswitchs": "キースイッチ",
      "Pcb": "基板",
      "PCB": "基板",
      "Top": "表",
      "Bottom": "裏",
      "Firmware": "ファームウエア",
      "G-Code": "切削データ",
      "Order": "注文",
      "HOME": "ホーム",
      "EDITOR": "エディター",
      "PROJECT": "プロジェクト",
      "OPTIONS": "オプション",
      "ORDER": "注文",
      "Add": "追加",
      "Delete": "削除",
      "Proceed": "進む",
      "STOP DELETING": "削除を終了",
      "keycode": "キーコード",
      "width": "幅",
      "empty": "空",
      "apply": "適用",
      "Checkout": "チェックアウト",
      "Pay": "支払い",
      "generating": "ファイルが生成されて"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
